.inSightPannel{
    width:380px;
    height:auto;
    position: absolute;
    background: #fff;
    z-index: 30;
    right:0;
    top:0%;
    transition: 300ms;
    box-shadow: 2px 2px 10px 2px rgba(0 ,0, 0,  0.5);
    .pannelHeader{
      width: 100%;
      height:50px;
      position: relative;
      border-bottom: 1px solid #ccc;
      color:#000;
      font-size: 14px;
      padding: 0px 40px 0px 10px;
      line-height: 50px;
      float: left;
    }
    .closeButton{
      width:35px;
      height: 35px;
      position: absolute;
      background: url(../../assets/images/close.png)no-repeat;
      background-size: auto 15px;
      background-position: center;
      cursor: pointer;
      right: 0;
      top:0;
      bottom: 0;
      margin: auto;
    }
  }
  .closePannel{
    transform: translateX(110%);
  }
  .insightCardHolder{
    width:100%;
    height: auto;
    float: left;
    padding: 10px 10px;
    overflow-y: auto;
    max-height: 650px;
    .cardHolder{
      width: 100%;
      height: auto;
      padding: 10px;
      float: left;
      margin-bottom: 10px;
      box-shadow: 2px 2px 10px 2px rgba(0 ,0, 0,  0.25);
      border-radius: 5px;
    }
    h3{
      font-size: 14px;
      font-weight: bold;
      color: #54585a;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 0;
      list-style: none;
      li {
        font-size: 14px;
        font-weight: normal;
        color: #54585a;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;
  
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 4px;
          background-color: #e71316;
          width: 10px;
          height: 10px;
          border-radius: 1rem;
        }
      }
    }
  }
  .filterTab{
    width:100%;
    height:35px;
    float: left;
    background: #f2f0f0;
    .tab{
      width: 20%;
      height: 100%;
      float: left;
      color:#000;
      font-size: 13px;
      text-align: center;
      line-height: 35px;
      border-right: 1px solid #fff;
      transition: 300ms;
      cursor: pointer;
    }
    .tab:hover{
      color:#fff;
      background: #000;
    }
    .activeTab{
      color:#fff;
      background: #000;
    }
  }
.container {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
    min-height: calc(100vh - 106px);
  }

  .headerSection {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e3e4;
    padding: 10px 20px;
    
  }
  .paremtHeaderSection{
    padding: 0;
    display: flex;
    align-items: center;
  }

  .backBtn {
    border: 1px solid #78797c;
    border-radius: 5px;
    padding: 6px 5px 3px 20px;
    font-size: 14px;
    position: relative;
    margin-right: 20px;
    cursor: pointer;
    &:after {
      border-right: 5px solid #78797c;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      content: "";
      position: absolute;
      left: 8px;
      top: 9px;
    }
  }

  .reportContainer {
    border-radius: 14px;
    background-color: #fff;
    padding: 5px;
  }
  .heading {
    color: #54585a;
    font-size: 18px;
    font-weight: bold;
    font-family: "Helvetica";
    display: inline-block;
    width: 97%;
  }
  .resetBtn {
    display: inline-block;
    border: 1px solid #78797c;
    border-radius: 5px;
    padding: 6px 10px 3px 10px;
    font-size: 14px;
    position: relative;
    left: -10px;
    cursor: pointer;
    &:after {
      border-right: 5px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
    }
  }
  
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center;
  span {
    font-size: 18px;
    color: #54585a;
    font-weight: bold;
    font-family: "Helvetica";
  }
  li {
    a {
      color: #54585a;
      font-size: 16px;
      font-weight: bold;
      font-family: "Helvetica";
      padding-right: 20px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        background: url(../../assets/images/bread-crumb-arrow.png) no-repeat;
        width: 12px;
        height: 22px;
        background-size: 8px;
      }
    }
    &.breadcrumbItem.active {
      font-size: 18px;
      color: #54585a;
      font-weight: bold;
      font-family: "Helvetica";
    }
  }
}
.favoriteBreadcrum{
  display: flex;
}
.breadcrumParent{
  flex: 1;
}

.primarycontainer {
  padding: 0 20px;
  display: inline-block;
  width: 100%;
}
.layoutbx {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  min-height: 253px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  position: relative;
  overflow:hidden
}

.cxheading {
  padding: 0 20px;
  font-family: "NeueHelvetica";
  font-size: 16px;
  font-weight: bold;
  color: #54585a;
  border-bottom: 1px solid #e2e3e4;
  margin: 0;
  height: 50px;
  line-height: 56px;
  cursor: pointer;
}

.customreport {
  height: 800px;
  width: 100%;
  display: none;
}

.backBtn {
  display: inline-block;
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 6px 5px 3px 15px;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  &:after {
    border-right: 5px solid #78797c;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
  }
}

.resetBtn {
  display: inline-block;
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 6px 10px 3px 10px;
  font-size: 14px;
  position: relative;
  left: 10px;
  cursor: pointer;
  &:after {
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
  }
}

.nextbtn {
  display: inline-block;
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 6px 20px 3px 5px;
  position: absolute;
  cursor: pointer;
  right: 40px;
  text-align: center;
  &:after {
    border-left: 5px solid #78797c;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    right: 6px;
    top: 11px;
  }
}
.childheading {
  border-bottom: 1px solid #e2e3e4;
  padding: 10px 20px;
}
.childheadingtext {
  font-family: "NeueHelvetica";
  font-size: 16px;
  font-weight: bold;
  color: #54585a;
}
.right167 {
  right: 167px;
}
.ifrmHolder{
  width: 100%;
  height: auto;
  transition: 300ms;
}
.pRight31{
padding-right: 31%;
height: 670px;
iframe{
  height: 100%;
}
}
.container {
	display: inline-block;
	width: 100%;
	padding: 0 20px 20px;
	// min-height: calc(100vh - 110px);
}

.layoutRow {
	box-sizing: border-box;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-direction: normal;
	-webkit-box-orient: horizontal;
	-webkit-flex-direction: row;
	flex-direction: row;
}

.layoutAlignCenter {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-grid-row-align: center;
	align-items: center;
	-webkit-align-content: center;
	align-content: center;
	-webkit-box-pack: center;
	min-height: 550px;
}

.parent {
	padding: 10px 45px;
    font-size: 14px;
    background: #54585a;
    color: #fff;
    border-radius: 10px;
}

.parentLine {
	height: 1px;
	background-color: #54585a;
	width: 100px;
}

.linecontainer {
	height: auto;
	position: relative;
	.lines {
		height: 50px;
		display: flex;
		.line {
			height: 1px;
			background-color: #54585a;
			margin: 24.5px 0;
			width: 100px;
		}
		.label {
			margin: 10px 0px;
			background-color: #ffffff;
			font-size: 14px;
			width: 500px;
			height: 35px;
			display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 8px;
			padding-left:10px;
		}
	}
}
.linecontainer::after{
	content: '';
	position: absolute;
    width: 1px;
	height: calc(100% - 50px);
	top:0;
	bottom: 0;
	margin: auto;
	left: 0;
	border-left: 1px solid #54585a;
}
.childcontainer {
	width: 400px;
	height: 500px;
	background-color: red;
	.divs {
		margin-top: 30px;
	}
}
.labelTextNoChild{
	width: auto;
	display: flex;
	align-items: center;
	transition: 300ms;
}
.labelText{
	width: auto;
	display: flex;
	align-items: center;
	transition: 300ms;
	cursor: pointer;
	color: #429ad6;
}
.makeDisable{
	opacity: 0.5;
	pointer-events: none;
}
.weightage{
	width:auto;
	min-width:100px;
	height: auto;
	font-size:12px;
	color:#000;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-right: 10px;
   .labelForwtg{
	   width:auto;
       padding-right: 10px;
   }
}
.displayWtg{
	width: auto;
	font-weight: bold;
	position: relative;
}
.cPointer{
	cursor: pointer;
}
.editIcon{
	width:25px;
	height: 25px;
	position: absolute;
	right:-14px;
	background: url('../../../assets/images/download.png')no-repeat;
	background-position: center;
	background-size: auto 23px;
	border-radius: 50%;
	z-index: 1;
	display: none;
	transition: 300ms;
	cursor: pointer;
	top:5px
}
.weightage:hover .editIcon, .editIcon:hover{
	display: block
}
.saveHolder{
	width:20px;
	height:20px;
	background: url(../../../assets/images/greenTik.png)no-repeat;
	background-position: center;
	background-size: auto 23px;
	float: left;
	cursor: pointer;
}
.cancelHolder{
	width: 16px;
	height: 16px;
	background:url(../../../assets/images/close.png)no-repeat;
	background-position: center;
	background-size: auto 10px;
	float: left;
	border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 3px;
	cursor: pointer;
}
.weightageInput{
	width:50px;
	border-radius: 5px;
}
.error {
	font-size: 14px;
	color: #e71316;
	margin: 10px 0px;
    padding: 10px;
}
.chieldLabel {
	margin: 10px 0px;
	background-color: #ffffff;
	font-size: 13px;
	width: 350px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-radius: 8px;
	padding:10px 0px 10px 10px;
}
// back button
.buttonHolder{
	position: absolute;
	top: 0%;
}
.backBtn {
	border: 1px solid #78797c;
	border-radius: 5px;
	padding: 6px 5px 3px 20px;
	font-size: 14px;
	position: relative;
	margin-right: 20px;
	cursor: pointer;
	&:after {
	  border-right: 5px solid #78797c;
	  border-top: 5px solid transparent;
	  border-bottom: 5px solid transparent;
	  content: "";
	  position: absolute;
	  left: 8px;
	  top: 9px;
	}
  }
  .childNode{
	.lines {
	.line {
		width: 40px;
	}
    }
  }
  .reduceWidth{
	  .error{
		  width:205px;
		  padding: 0px 5px;
	  }
	.parentLine {
		width: 45px;
	}  
  }
  .divisionCss{
	  display: flex;
	  flex-direction: column;
	  position: relative;
	  .lines{
		margin: 10px 0px;
	  }
	  .labelTextNoChild{
		  max-width: 60%;
	  }
	  .label{
		  height: 50px !important;
		  margin: 0px !important;
		  width: 590px !important;
	  }
	  .editIcon{
		  top:12px !important
	  }
  }
  .divisionCss::after{
	content: '';
	width: 2px;
	height: calc(100% - 71px);
	position: absolute;
	background: #f2f0f0;
	bottom:0;
	left: 0;
	z-index: 1;
}
.cAuto{
	cursor: auto !important;
}
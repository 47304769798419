.cardContainer {
  cursor: pointer;
  border-radius: 14px;
  // min-height: 180px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  font-family: "Helvetica";
  width: calc(100% - 20px);
  margin-right: 20px;
  position: relative;
  &.cardMargin {
    margin: 0 10px;
  }
  &.cardAfter {
    margin-top: 10px;
  }
  &.cardAfter::after {
    content: "";
    position: absolute;
    right: -12px;
    top: calc(50% - 7px);
    border-left: 14px solid #fff;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
  }
  &:focus {
    outline: 0 none;
  }
  .cardSubHeader0{
    font-size: 14px;
    color: #54585a;
    padding: 10px 10px 7px 10px;
  }
  .cardSubHeader.kpitile{
    border-bottom: 1px solid #e2e3e4;
  }
  .cardSubHeader{    
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 7px 10px;
    .cardSubTitle{
      font-family: Helvetica;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #54585a;
      padding-top: 10px;
    }
    .cardSubTitlehidden{
      font-family: Helvetica;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      padding-top: 10px;
    }
    .subTitle{
      margin: 0;
      padding: 6px 10px;
      border-radius: 14px;
      box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
      background-color: #78797c;
      color: #ffffff;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }
  .cardHeader{
    padding: 10px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
    .cardTitle{
      font-size: 14px;
      font-weight: bold;
      color: #54585a;
      line-height: 1;
    }
    .logoIcn {
      background-color: #78797c;
      width: 32px;
      height: 32px;
      border-radius: 1rem;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
    .headerLeft {
      display: flex;
      align-items: center;
      .product-logo {
        background: url(../../assets/images/company-logo-1.png) no-repeat center;
        border-radius: 11.5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #e2e3e4;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        float: left;
      }
      .productTitle {
        font-size: 16px;
        font-weight: bold;
        color: #54585a;
      }
    }
    .headerRight {
      flex: 1;
      .innerTitle {
        font-size: 12px;
        font-weight: 500;
        color: #98999c;
        display: block;
        text-align: right;
      }
      .innerValue {
        display: block;
        color: #4aa707;
        font-size: 16px;
        font-weight: 500;
        text-align: right;
      }
    }
    .title {
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
      color: #54585a;
      background: url(../../assets/images/company-logo-1.png) no-repeat;
      padding-left: 35px;
    }
    .favorite-unpin-red{
      content: "";
      position: relative;
      left: 0;
      top: 10px;
      width: 46px;
      height: 46px;
      cursor: pointer;
    }
    
  }
  .cardFooter {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0 0 14px 14px;
  }

  .cardBody {
    padding: 3px 0;
    display: flex;
    .innerLayout1{
      width: 100%;
      text-align: center;
      border-right: 1px solid #e2e3e4;
    }
    .innerLayout1, .innerLayout2 {
      box-sizing: border-box;
      font-family: Helvetica;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #78797c;
      &:last-child {
        border-right: 0 none;
      }
      .bodyTitle {
        color: #78797c;
        font-size: 14px;
        font-weight: 500;
        display: block;
        text-align: center;
      }
      .bodyValue {
        font-size: 18px;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        text-align: center;
        color: #54585a;
        .arrowUp,
        .arrowDown {
          background: url(../../assets/images/up-arrow.png) no-repeat;
          width: 8px;
          height: 20px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowNoChange {
          background: url(../../assets/images/no-change-arrow.png) no-repeat;
          width: 14px;
          height: 11px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowDown {
          transform: rotate(180deg);
        }
      }
    }
  }  
}
.favoritePinRed{
  background: url(../../assets/images/unpin-hover.png) no-repeat;
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  width: 60px;
  height: 60px;
  cursor: pointer;
} 
.tooltip .tooltiptext {
  visibility: hidden;
  width: 145px;
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 70%;
  opacity: 0.9;
  font-family: "Helvetica";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.nbaHidden {
  color: #ffffff;
}


.cxContainer {
  display: inline-block;
  width: 100%;
  padding: 0 20px;
}

.leftContent {
  float: left;
  width: 65%;
}

.carouselItem {
  padding: 0;
  width: 100%;
  .cardHeader {
    padding: 10px;
    border-bottom: 1px solid #e2e3e4;
    display: flex;
    justify-content: space-between;
    .cardTitle {
      font-size: 18px;
      font-weight: bold;
      color: #54585a;
      line-height: 32px;
    }
    .logoIcn {
      background-color: #78797c;
      width: 32px;
      height: 32px;
      border-radius: 1rem;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      line-height: 32px;
    }
    .headerLeft {
      display: flex;
      align-items: center;
      .product-logo {
        background: url(../../../assets/images/company-logo-1.png) no-repeat center;
        border-radius: 11.5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #e2e3e4;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        float: left;
      }
      .productTitle {
        font-size: 16px;
        font-weight: bold;
        color: #54585a;
      }
    }
    .headerRight {
      flex: 1;
      .innerTitle {
        font-size: 12px;
        font-weight: 500;
        color: #98999c;
        display: block;
        text-align: right;
      }
      .innerValue {
        display: block;
        color: #4aa707;
        font-size: 16px;
        font-weight: 500;
        text-align: right;
      }
    }
    .title {
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
      color: #54585a;
      background: url(../../../assets/images/company-logo-1.png) no-repeat;
      padding-left: 35px;
    }
  }
}

.cardContainer {
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  font-family: "Helvetica";
  width: calc(100% - 20px);
  margin-right: 10px;
  cursor: pointer;
  &:focus {
    outline: 0 none;
  }
  .cardHeader {
    padding: 10px;
    border-bottom: 1px solid #e2e3e4;
    display: flex;
    justify-content: space-between;
    .cardTitle {
      font-size: 18px;
      font-weight: bold;
      color: #54585a;
      line-height: 32px;
    }
    .logoIcn {
      background-color: #78797c;
      width: 32px;
      height: 32px;
      border-radius: 1rem;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      line-height: 32px;
    }
    .headerLeft {
      display: flex;
      align-items: center;
      .product-logo {
        background: url(../../../assets/images/company-logo-1.png) no-repeat center;
        border-radius: 11.5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #e2e3e4;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        float: left;
      }
      .productTitle {
        font-size: 16px;
        font-weight: bold;
        color: #54585a;
      }
    }
    .headerRight {
      flex: 1;
      .innerTitle {
        font-size: 12px;
        font-weight: 500;
        color: #98999c;
        display: block;
        text-align: right;
      }
      .innerValue {
        display: block;
        color: #4aa707;
        font-size: 16px;
        font-weight: 500;
        text-align: right;
      }
    }
    .title {
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
      color: #54585a;
      background: url(../../../assets/images/company-logo-1.png) no-repeat;
      padding-left: 35px;
    }
  }
  .cardFooter {
    padding: 12px 0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0 0 14px 14px;
    b {
      margin-left: 10px;
    }
  }
  .cardBody {
    padding: 20px 0;
    display: flex;
    .innerLayout {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
      .bodyTitle {
        color: #78797c;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
        display: block;
        text-align: center;
      }
      .bodyValue {
        font-size: 20px;
        font-weight: bold;
        display: block;
        text-transform: uppercase;
        text-align: center;
        color: #54585a;
        .arrowUp,
        .arrowDown {
          background: url(../../../assets/images/up-arrow.png) no-repeat;
          width: 8px;
          height: 20px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowDown {
          transform: rotate(180deg);
        }
      }
    }
    .outerSegment {
      display: flex;
      margin-bottom: 30px;
      .leftSeg,
      .rightSeg {
        flex: 0 0 50%;
        label {
          display: flex;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 5px;
          color: #98999c;
        }
        span {
          font-size: 20px;
          font-family: "Helvetica";
          font-weight: 600;
          color: #54585a;
        }
      }
    }
    .segment {
      flex: 0 0 50%;
      label {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color: #98999c;
      }
      span {
        font-size: 20px;
        font-family: "Helvetica";
        font-weight: 600;
        color: #4aa707;
      }
    }
    .progressSec {
      margin-bottom: 30px;
    }
    .progressTitle {
      font-size: 14px;
      font-weight: 500;
      color: #98999c;
    }
    .myProgress {
      width: 80%;
      background-color: #e2e3e4;
      border-radius: 4px;
      height: 8px;
      margin: 16px 0 0;
      display: inline-flex;
      .myBar {
        width: 1%;
        height: 8px;
        // background-color: #4aa707;
        border-radius: 4px;
      }
    }
    .myProgressCount {
      width: 20%;
      display: inline-flex;
      padding-left: 10px;
      color: #54585a;
      font-size: 20px;
      font-family: "Helvetica";
      font-weight: 600;
    }
  }
}

.rightContent {
  float: left;
  width: 35%;
  .groupView {
    .groupViewHeader {
      border-radius: 14px 14px 0 0;
      background: #e71316;
      padding: 20px 20px 15px;
      color: #fff;
    }
    .groupViewBody {
      background: #fff;
      border-radius: 0 0 14px 14px;
      padding-top: 20px;
      margin-bottom: 20px;
      min-height: 700px;
    }
  }
}

.scoreGrid {
  display: flex;
  margin-bottom: 20px;
}

.layoutBx {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  min-height: 253px;
  margin-bottom: 20px;
  float: left;
  width: calc(100% - 20px) !important;
  margin-right: 20px;
}

.cxHeading {
  padding: 0 20px;
  font-family: "NeueHelvetica";
  font-size: 16px;
  font-weight: bold;
  color: #54585a;
  border-bottom: 1px solid #e2e3e4;
  margin: 0;
  height: 50px;
  line-height: 56px;
}

.groupTopSection {
  padding: 0 0 20px;
  display: flex;
}

.innerLayout {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  box-sizing: border-box;
  border-right: 1px solid #e2e3e4;
  &:last-child {
    border-right: 0 none;
  }
}

.bodyTitle {
  color: #78797c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.bodyValue {
  color: #54585a;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.myProgress {
  width: 100%;
  background-color: #e2e3e4;
  border-radius: 4px;
  height: 8px;
  margin: 10px 0 0;
  display: inline-flex;
  .myBar {
    width: 1%;
    height: 8px;
    // background-color: #4aa707;
    border-radius: 4px;
  }
}

.arrowUp,
.arrowDown {
  background: url(../../../assets/images/up-arrow.png) no-repeat 0 0/ 5px 12px;
  width: 5px;
  height: 12px;
  display: inline-block;
  margin: 0 5px;
}
.arrowDown {
  transform: rotate(180deg);
}
.arrowNoChange {
  background: url(../../../assets/images/no-change-arrow.png) no-repeat;
  width: 14px;
  height: 8px;
  display: inline-block;
  margin-left: 5px;
}
.cardContainer .cxCardBody {
  flex-direction: column;
  padding: 10px;
}

.cardContainer .cardHeader {
  align-items: center;
}

.nValue {
  display: block;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  background-color: #78797c;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  & .tooltipForCX{
    visibility: hidden;
    position: absolute;
    background-color: black;
    width: 200px;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: normal;
    font-family: "Helvetica";
    color: #ffffff;
    bottom: 36px;
    margin-left: -100px;
    left: 50%;
  }
  &:hover .tooltipForCX{
    visibility: visible;
  }
  & .tooltipForCX::after{
    content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  }
  
}




.percentageInc {
  font-size: 12px;
  align-self: center;
  span {
    font-weight: 600;
    color: #4aa707;
  }
}

.progressVal {
  font-size: 18px;
  font-weight: bold;
  color: #54585a;
  font-family: "NeueHelvetica";
  letter-spacing: 0.5px;
}

.indexDesc {
  font-size: 12px;
  font-weight: bold;
  color: #54585a;
  span {
    font-size: 12px;
  }
}

.noTransactions {
  font-size: 12px;
  font-weight: bold;
  color: #54585a;
  padding: 15px 0;
  span {
    font-size: 12px;
  }
}

.indexSession {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .kpiLeft {
    font-size: 14px;
    font-weight: bold;
    color: #54585a;
    margin-right: 20px;
  }
  .kpiRight {
    font-size: 18px;
    font-weight: bold;
    color: #54585a;
  }
}

.navDots {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  span {
    width: 7px;
    height: 7px;
    cursor: pointer;
    background: rgba(121, 122, 125, 0.3);
    border-radius: 1rem;
    margin-right: 10px;
    &.active {
      background-color: rgba(121, 122, 125, 0.8);
    }
  }
}


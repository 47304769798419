.tableResponsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-family: "Helvetica";
    border: 0 none;
    border-collapse: collapse;
    border-radius: 14px;
    overflow: hidden;
  }
  
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #e71316;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
  
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  
  .table thead th {
    vertical-align: bottom;
    border: 0 none;
    text-align: left;
  }
  
  .Table td {
    color: #54585a;
    font-weight: 600;
    cursor: pointer;
  }
  
  .table td,
  .table th {
    padding: 0.75rem;
    vertical-align: top;
    font-size: 14px;
    .points {
      padding: 0 3px 0 10px;
    }
  }
  
  tbody tr:nth-of-type(even) {
    background-color: #f8f7f7;
  }
  
  tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }
  
  tbody tr:hover{
    background-color:#f2f0f0;
  }

  .title {
    padding: 10px 50px;
    border-radius: 14px 14px 0 0;
    background-color: #e71316;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    font-family: "Helvetica";
    display: inline-flex;
  }
  
  .upArrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #4aa707;
    margin-left: 5px;
    display: inline-block;
  }
  
  .downArrow {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #d01214;
    margin-left: 5px;
    display: inline-block;
  }
  
  
.sortIcn {
  background: url(../../assets/images/icn-sort.png) no-repeat;
  width: 7px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
}
.sortIcnInvert {
  background: url(../../assets/images/icn-sort-invert.png) no-repeat;
  width: 7px;
  height: 10px;
  margin-left: 10px;
  display: inline-block;
}
.nodata {
  padding: 100px;
  text-align: center;
  font-size: 14px;
}
.red {
  color: #d01214;
}
.green {
  color: #4aa707;
}
.dash {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #54585a;
  font-family: "NeueHelvetica";
  letter-spacing: 0.5px;
}
.skusLegend {
    text-align: center;
    color: #506883;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.skuRevenue {
    width: 10px;
    height: 10px;
    background-color: #1f77b4;
    margin: 3px;
  }
  .skuGrowth {
    width: 10px;
    height: 10px;
    background-color: #ff7f0e;
    margin: 3px;
  }
  .footer {
    display: flex;
    padding: 0 10px;
  }
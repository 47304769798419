.App {
  background-color: #f2f0f0;
  min-height: 100vh;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.innerContainer {
  margin-left: 83px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tableclass {
  width: 100%;
}

table,
th,
td {
  border-collapse: collapse;
}

.forwardArrowSubTitle {
  content: "";
  background: url(./assets/images/bread-crumb-arrow.png) no-repeat;
  width: 12px;
  background-size: 7px;
  margin-left: 5px;
}

.forwardArrow {
  content: "";
  background: url(./assets/images/bread-crumb-arrow.png) no-repeat;
  width: 12px;
  background-size: 7px;
  margin-left: 5px;
}

.forwardTitleArrow {
  content: "";
  background: url(./assets/images/bread-crumb-arrow.png) no-repeat;
  width: 12px;
  background-size: 7px;
  margin-left: 5px;
}

.favoriteFlex {
  display: flex;
  white-space: nowrap;
}

.c3-chart .c3-chart-texts .c3-text {
  fill: black !important;
}

.modal-header .close {
  display: none !important;
}

// custom css changes for inbuilt
.react-datepicker-wrapper {
  width: auto !important;
}

.react-datepicker__input-container {
  input[type=text] {
    width: 98px !important;
    padding-top: 5px !important;
  }
}

.react-datepicker__close-icon::after {
  background-color: #e71316 !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #e71316 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #e71316 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #e71316 !important;
}
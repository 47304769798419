.container {
    display: inline-block;
    width: 100%;
    padding: 0 20px;

    .paramsItems{
      width: 25%;
    }
    .searchParams {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      border: 1px solid #e2e3e4;
      border-radius: 15px;
      margin-bottom: 15px;
      font-size: 14px;
    }

    .searchArea {
      padding: 25px;
      text-align: center;
      display: flex;
      .firstDiv {
        width: 25%;
      }
      .secondDiv {
        width: 50%;
        padding: 10px;
      }
      .search {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
      }
    }

    .headerSection {
        padding: 0;
        display: flex;
        align-items: center;
      }
    
      .backBtn {
        border: 1px solid #78797c;
        border-radius: 5px;
        padding: 6px 5px 3px 20px;
        font-size: 14px;
        position: relative;
        margin-right: 20px;
        cursor: pointer;
        &:after {
          border-right: 5px solid #78797c;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          content: "";
          position: absolute;
          left: 8px;
          top: 9px;
        }
      }
      .heading {
        color: #54585a;
        font-size: 18px;
        font-weight: bold;
        font-family: "Helvetica";
        display: inline-block;
        width: 100%;
      }
}
.container{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    text-align: center;
}
.gained {
    border-right: 1px solid #e2e3e4;
    padding: 10px;
}
.lost {
    padding: 10px;
}
.label {
    color: #78797c;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.value {
    color: #78797c;
    font-size: 20px;
    font-weight: bold;
}
.container {
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 106px);
}
.productsContainer {
  padding: 0 20px;
  display: flex;
}
.productHead {
  padding: 0;
  display: flex;
  align-items: center;
}

.customerLayoutBx {
  padding: 20px 0;
  float: left;
  width: 100%;
}

.backBtn {
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 7px 8px 4px 20px;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  &:after {
    border-right: 5px solid #78797c;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    left: 9px;
    top: 10px;
  }
}

.groupSessionBx {
  padding: 0 0 20px 20px;
  width: 27%;
  float: left;
  .cardContainer {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      right: -12px;
      top: calc(50% - 7px);
      border-left: 14px solid #fff;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }
}

.productBox {
  width: 100%;
}

.container {
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 106px);
  .radioWrapper{
    padding: 15px 10px 15px 10px;
    display: inline-block;
    margin-bottom: 20px;
    margin-left:20px;
    width: 265px;
    height: 60px;
    border: 5px solid red;
    border-radius:15px;
    .radioLabel{
      width:115px;
      display: inline-block;
      position: absolute;
      top:20px;
    }
    .radioForm{
      display: inline-block;
      position: absolute;
      left:150px;
    }
    .formRadioInline{
      margin-right:15px;
    }
    
   :global(.form-check-label){
        margin-bottom: -4px;  
    }
  }
  .dummyWrapper{
    padding: 0 20px;
    display: inline-block;
    margin-bottom: 10px;
    width: 17%;
  }
}
.productsContainer {
  padding: 0 20px;
  display: flex;
  margin-bottom: 10px;
}

.customerLayoutBx {
  float: left;
  width: 100%;
}
.groupSessionBx {
  padding: 0 0 20px 20px;
  width: 27%;
  float: left;
  .cardContainer {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      right: -12px;
      top: calc(50% - 7px);
      border-left: 14px solid #fff;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }
}

.outerGrid {
  border-radius: 14px;
  border: solid 1px #e2e3e4;
  padding: 10px 10px 10px 0;;
  .cardContainer {
    margin: 0 10px;
    cursor: pointer;
  }
}

.tilePaddingbottom{
  padding-bottom: 10px;
}

.tilePaddingtop{
  padding-top: 10px;
}

.productBox {
  width: 100%;
}

.underDesign {
  height: 190px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.underDesignText {
  font-family: NeueHelvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #54585a;
  padding: 5%;
}
.favLayoutBx {
  background-color: rgba(0, 0, 0, 0.1);
    float: left;
    width: 100%;
    padding: 20px;
}
.ackTitle{
  // font-family: "NeueHelveticaBd";
  font-family: NeueHelvetica;
  color: #54585a;
}
.spinAlign{
  margin-right: 20px;
  margin-top: 10px;
}
.customBtn {
	background-color: #e71316 !important;
	border: 1px solid #e71316 !important;
}
//tabs
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 20px 0 0;
    list-style: none;
    li {
      button {
        font-family: "NeueHelvetica";
        font-size: 14px;
        font-weight: 500;
        color: #54585a;
        padding: 10px 50px 5px;
        border: 0 none;
        cursor: pointer;
        &:focus {
          outline: 0 none;
        }
        &.active {
          border-radius: 14px 14px 0 0;
          background-color: #e71316;
          color: #fff;
          border: 0 none;
          cursor: default;
        }
      }
    }
  }
  .nav1 {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 20px 0 0;
    list-style: none;
    background-color: #54585A;
    border-radius: 15px 15px 0 0;
    border-top: 5px solid #e71316;
    margin: 0;
    li {
      button {
        font-family: "NeueHelvetica";
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        padding: 10px 50px 5px;
        border: 0 none;
        cursor: pointer;
        background-color: #54585A;
        border-right: 1px solid #ffffff;
        opacity: 0.7;
        border-radius: 15px 0 0 0;
        &:focus {
          outline: 0 none;
        }
        &.active {
          opacity: 1;
          border-radius: 15px 0 0 0;
          background-color: #54585A;
          color: #ffffff;
          border: 0 none;
          cursor: default;
          border-right: 1px solid #ffffff;
        }
      }
    }
  }
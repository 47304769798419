.exportFlex {
  color: #54585a;
  text-align: end;
  display: flex;
}

.downloadImg {
  background: url(../../assets/images/download_img.svg) no-repeat;
  content: "";
  position: relative;
  left: 0px;
  top: 7px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 5px;
}

.exportLabel {
  margin-left: auto;
  margin-top: 5px;
  font-weight: bold;
  color: #54585a;
  font-family: "Helvetica";
}

.space {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 7px;
}

.customCalendar {
  display: flex;
  flex-direction: column;
  height: 45px;
}
.displayFlex{
  display: flex;
}

.errorMsg {
  color: #e71316;
  font-size: 13px;
}
.spinAlign{
  margin-left: 5px;
  margin-top: 5px;
}
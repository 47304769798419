.container {
    display: inline-block;
    width: 100%;
    padding: 0 20px;
  }
.listcontainer {
    // padding-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dataCardWrapper{
  width:33%;
  height:auto;
  margin-top: 7px;
}

.fadeOutLinks{
  .chieldKpi{
    opacity: 0.5;
    pointer-events: none;
  }
}
.dataCard{
  width:100%;
  height:auto;
  float: left;
  background: #fff;
  border-radius: 14px;
  .headerKpi{
    width:100%;
    height:auto;
    padding:15px 15px 5px;
    font-size: 14px;
    font-weight: bold;
    color: #54585a;
    border-bottom: 1px solid #e2e3e4;
    float: left;
  }
  
  .chieldKpi{
    width:100%;
    height: auto;
    float: left;
    padding:15px;
    font-size: 14px;
    color: #54585a;
    position: relative;
    transition: 300ms;
    cursor: pointer;
  }
  .chieldKpi::after{
    content: '';
    width: calc(100% - 20px);
    height: 0px;
    position: absolute;
    bottom: 0;
    left:15px;
    border-bottom: 1px solid #e2e3e4;
  }
  .chieldKpi:hover{
    background-color: #f2f0f09e;
  }
  .chieldKpi:last-child::after{
    border-bottom: none;
  }
}
.pLeft15{
  padding-left: 15px;
}
.arrowLeft{
  width: 15px;
  height: 15px;
  position: absolute;
  right:5px;
  top:0;
  bottom: 0;
  margin: auto;
  background: url('../../../assets/images/icn-dropdown.png')no-repeat;
  background-position: center;
  background-size: auto 5px;
  transform: rotate(269deg);
}
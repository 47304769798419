.attributes {
  .attributesHeader {
      border-radius: 14px 14px 0 0;
      background: #e71316;
      padding: 20px 20px 15px;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .whiteGlove {
    background: url(../../assets/images/icn-crown.png) no-repeat;
    width: 36px;
    height: 25px;
  }
  .topContainer {
    margin: 0 20px;
  }
  .attributesBody {
      background: #fff;
      border-radius: 0 0 14px 14px;
  }
  .segmentSection {
    padding: 20px 0;
      display: flex;
      border-top: 1px solid #e2e3e4;
  }
  .segment {
    flex: 50%;
    max-width: 50%;
    box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
  }
  .groupTopSection {
      padding: 20px 0;
      display: flex;
    }
    .borderBottom{
      border-bottom: 1px solid #e2e3e4;
    }
    
    .innerLayout {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      box-sizing: border-box;
      border-right: 1px solid #e2e3e4;
      &:last-child {
        border-right: 0 none;
      }
    }
    
    .bodyTitle {
      color: #78797c;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      display: block;
      text-align: center;
    }
    
    .bodyValue {
      color: #54585a;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    
}
.header {
    height: 6px;
    background-color: #e71316;
}
.CAS{
    background: #fff;
    border-radius: 0;
    min-height: 400px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
    margin-bottom: 20px;
    display: flex;
}
.CASInsights {
    border-left: 1px solid #e2e3e4;
}
.CASGraphHeading, .CASInsightsHeading{
    padding: 0 20px;
    font-family: "NeueHelvetica";
    font-size: 16px;
    font-weight: bold;
    color: #54585a;
    border-bottom: 1px solid #e2e3e4;
    margin: 0;
    height: 50px;
    line-height: 56px;
    span{
      font-weight: normal;
      font-size: 14px;
    }
}
.childheading {
  border-bottom: 1px solid #e2e3e4;
  padding: 10px 20px;
  height: 50px;
}
.overall {
  display: inline-block;
    display: inline-block;
    font-family: "NeueHelvetica";
    font-size: 16px;
    font-weight: 700;
    color: #54585a;
    margin-top: 6px;
  span{
    font-weight: normal;
    font-size: 11px;
  }
}
.nextbtn {
  float: right;
  display: inline-block;
  border: 1px solid #78797c;
  border-radius: 5px;
  padding: 6px 16px 0px 13px;
  cursor: pointer;
  text-align: center;
  position: relative;
  &:after {
    border-left: 5px solid #78797c;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    content: "";
    position: absolute;
    top: 10px;
    right: 8px;
  }
  span {
    display: inline-block;
    position: relative;
    top: -1px;
    right: 5px;
  }
}
.CASGraph{
    //border-right: 1px solid #e2e3e4;
    //width: 55%;
}
.indexDesc {
    opacity: 0.3;
    flex: 0 0 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #54585a;
    }
    ul {
      padding-left: 0;
      list-style: none;
      li {
        font-size: 14px;
        font-weight: normal;
        color: #54585a;
        margin-bottom: 20px;
        position: relative;
        padding-left: 20px;
  
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 4px;
          background-color: #e71316;
          width: 10px;
          height: 10px;
          border-radius: 1rem;
        }
      }
    }
  }
  .resetBtn {
    display: inline-block;
    border: 1px solid #78797c;
    border-radius: 5px;
    padding: 6px 10px 3px 10px;
    font-size: 14px;
    position: relative;
    left: 10px;
    cursor: pointer;
    &:after {
      border-right: 5px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
    }
  }
  .layoutBx {
    background: #fff;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
    min-height: 253px;
    margin-bottom: 10px;
    float: left;
    width: 100% !important;
  }
  .underDesign {
    height: 190px;
    width: 100%;
    font-family: NeueHelvetica;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #54585a;
    padding: 10%;
  }